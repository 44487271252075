// .light {
//   .multi-line-option {
//     .mat-option-text {
//       padding: 0;
//       & > * {
//         margin: 0;
//         padding: 0;
//         font-weight: normal;
//         font-size: inherit;
//       }
//       .mat-line {
//         line-height: 1em;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;
//         box-sizing: border-box;
//       }
//     }
//   }
// }
@import '~@angular/material/theming';

$typography-config: mat-typography-config();

@mixin multi-line-option-typography($config) {
  .multi-line-option {
    @include mat-line-base(mat-font-size($config, body-1));
    .mat-line {
      line-height: 1em;
    }
    &.mat-3-line {
      @include mat-line-base(mat-font-size($config, caption));
      font-size: mat-font-size($config, body-1);
    }
  }
}

@include multi-line-option-typography($typography-config);

.multi-line-option {
  .mat-option-text {
    @include mat-line-wrapper-base();
  }
}
.cdk-virtual-scroll-orientation-vertical.disable-horizontal-scroll .cdk-virtual-scroll-content-wrapper {
  // min-width: 100%;
  max-width: 100%;
}
