// html template when use fixed-layout style
// <div class="fixed-layout">
//   <div class="fixed-layout-top"></div>
//   <div class="fixed-layout-body"></div>
//   <div class="fixed-layout-bottom"></div>
// </div>

@layer components {
  .fixed-layout {
    @apply w-full h-full grid auto-cols-auto grid-flow-row grid-rows-[auto_1fr_auto];
    grid-template-areas:
      'fixed-layout-top'
      'fixed-layout-body'
      'fixed-layout-bottom';

    .fixed-layout-top {
      grid-area: fixed-layout-top;
    }

    .fixed-layout-body {
      grid-area: fixed-layout-body;
      @apply overflow-hidden;
    }

    .fixed-layout-bottom {
      grid-area: fixed-layout-bottom;
    }
  }
}
